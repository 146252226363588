import React from "react";
import { motion } from "framer-motion";
import "./styles.css";

/**
 * This is an example of the repeat syntax in Framer Motion 2.5+
 *
 * repeat: The number of times you want the animation to repeat
 * repeatDelay: A delay, in seconds, the insert between each repetition
 * repeatType: Defaults to "loop". The type of repeat to use. Can be one of:
 * - "loop": Repeats the animation from the start
 * - "reverse": Alternates between forward and backwards playback
 * - "mirror": Switchs `from` and `to` alternately
 */
export default function App() {
  return (
    <motion.div
      className="handle"
      initial={{ x: -200 }}
      animate={{ x: 200 }}
      transition={{
        type: "spring",
        repeat: Infinity,
        repeatType: "mirror",
        repeatDelay: 0.1
      }}
    />
  );
}
